export const NetworkContextName = "Binance Mainnet";
export const Network = "Binance";
export const ACTIVE_NETWORK = 56;

//Testing
// export const ACTIVE_NETWORK = 97;
//Testing
export const nftAddress = "0xf9D74dd8894a5c10fD08Dc83919BaD1FFA2dbA9b";
// export const marketPlace = "0xfe2D4E408566c74E894B50F447bCB13B8E13722B";


// main///
export const marketPlace = "0x0f388215338f56e8003D40353B5bD25081a55D85";
// main///
/// Testing
// export const marketPlace = "0x9186Dc6e3Be1d6eDB9b40f64C8a22695C44a0304";
/// Testing

export const NetworkDetails = [
	{
		chainId: "56",
		chainName: "Binance - Mainnet",
		nativeCurrency: {
			name: "Binance - Mainnet",
			symbol: "BNB",
			decimals: 18,
		},
		rpcUrls: ["https://bsc-dataseed.binance.org/"],
		blockExplorerUrls: ["https://bscscan.com"],
	},
];

// export const NetworkDetails = [
// 	{
// 		chainId: "97",
// 		chainName: "BNB Testing",
// 		nativeCurrency: {
// 			name: "BNB Testing",
// 			symbol: "BNB",
// 			decimals: 18,
// 		},
// 		rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
// 		blockExplorerUrls: ["https://testnet.bscscan.com"],
// 	},
// ];
// const url = "http://172.16.1.132:8008";
// const url = "https://node-defi.mobiloitte.com";
// http://172.16.1.90:8008/api-docs/
// const url = "http://172.16.1.172:8008";
const url = "https://node.nftsdelivered.com";
// const url = "https://node-defi.mobiloitte.io/";


const Apiconfig = {
  adminLogin: `${url}/api/v1/admin/adminLogin`,
  getAdminProfile: `${url}/api/v1/admin/getAdminProfile`,
  dashboard: `${url}/api/v1/admin/dashboard`,
  getAlluser: `${url}/api/v1/admin/getAlluser`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  listNFT: `${url}/api/v1/admin/listNFT`,
  placedOrderList: `${url}/api/v1/admin/placedOrderList`,
  cancelAlreadyPlaceOrderList: `${url}/api/v1/admin/cancelAlreadyPlaceOrderList`,
  viewParticularNFT: `${url}/api/v1/admin/viewParticularNFT`,
  cancelAlreadyPlaceOrder: `${url}/api/v1/admin/cancelAlreadyPlaceOrder`,
  viewTransaction: `${url}/api/v1/admin/viewTransaction`,
  viewNftTransaction: `${url}/api/v1/admin/viewNftTransaction`,
  viewPlaceOrder: `${url}/api/v1/admin/viewPlaceOrder`,
  viewCancelOrder: `${url}/api/v1/admin/viewCancelOrder`,
  forgetPassword: `${url}/api/v1/admin/forgetPassword`,
  verifyOtp: `${url}/api/v1/admin/verifyOtp`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  listCategory: `${url}/api/v1/admin/listCategory`,
  deleteCategory: `${url}/api/v1/admin/deleteCategory`,
  viewCategory: `${url}/api/v1/admin/viewCategory`,
  editCategory: `${url}/api/v1/admin/editCategory`,
  addCategory: `${url}/api/v1/admin/addCategory`,
  reportsList: `${url}/api/v1/admin/listReport`,
  blockUnblockReport: `${url}/api/v1/admin/blockUnblockReport`,
  viewReport: `${url}/api/v1/admin/viewReport`,
  viewStaticPage: `${url}/api/v1/static/viewStaticContent`,
  editStaticPage: `${url}/api/v1/static/editStaticContent`,
};
// /api/v1/static/viewStaticContent

export default Apiconfig;

/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RiDashboardFill } from "react-icons/ri";
import { AiFillDashboard } from "react-icons/ai";
import { BiBookContent } from "react-icons/bi";
import PolymerIcon from "@material-ui/icons/Polymer";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Slide,
  Dialog,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

// import DashboardIcon from '@material-ui/icons';
// import AccessAlarmIcon  from '@material-ui/icons/AccessAlarm';
import {
  FaSignOutAlt,
  FaUserCircle,
  // FaSignInAlt,
  FaUser,
  // FaQuestionCircle,
  // FaCheckSquare,
  FaExchangeAlt,
  // FaPoll,
  FaRegEdit,
} from "react-icons/fa";
// import {GrTransaction} from 'react-icons/gr';
// import { AiFillSetting } from "react-icons/ai";
// import { MdAttachMoney } from "react-icons/md";
// import { MdAssessment } from "react-icons/md";
// import ReorderIcon from "@material-ui/icons/Reorder";
// import { PieChart as PieChartIcon } from "react-feather";
// import { RiDashboardFill } from "react-icons/ri";
import NavItem from "./NavItem";
const sections = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
      },
      {
        title: "Commission",
        icon: PolymerIcon,
        href: "/commission",
      },
    ],
  },
  {
    subheader: "USER",
    items: [
      {
        title: "User",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "User List",
            icon: "",
            href: "/User-List",
          },
          // {
          //   title: "Report user",
          //   icon: "",
          //   href: "/Report-User",
          // },
        ],
      },
    ],
  },

  // {
  //   subheader: "NFT LIST",
  //   items: [
  //     {
  //       title: "Nft-List",
  //       icon: ReorderIcon,
  //       href: "/NFT-LIST",
  //     },
  //   ],
  // },

  // {
  //   subheader: "TRANSACTION",
  //   items: [
  //     {
  //       title: "Transaction",
  //       icon: FaExchangeAlt,
  //       href: "/transaction",
  //     },
  //   ],
  // },
  {
    subheader: "CATEGORIES",
    items: [
      {
        title: "Categories",
        icon: RiDashboardFill,
        href: "/categories",
      },
    ],
  },

  {
    subheader: "NFT List",
    items: [
      {
        title: "Nft List",
        icon: FaRegEdit,
        href: "",
        items: [
          {
            title: "NFT List",
            icon: "",
            href: "/NFT-LIST",
          },

          {
            title: "PlaceOrder List",
            icon: "",
            href: "/placeorderlist",
          },
          {
            title: "CancelOrder List",
            icon: "",
            href: "/cancelorderlist",
          },
          {
            title: "Reported NFT's List",
            icon: "",
            href: "/Report-User",
          },
          // {
          //   title: "Report NFT List",
          //   icon: "",
          //   href: "/reportlist",
          // },
        ],
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "Static Content",
        icon: BiBookContent,
        // href: "/Show-Content",
        href: "/Termsof-Use",
      },
    ],
  },
  {
    subheader: "LOGOUT",
    items: [
      // {
      //   title: "Logout",
      //   icon: FaSignOutAlt,
      //   href: "/",
      // },
    ],
  },
];

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isNested: true,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={
          <div style={{ width: isNested ? "auto" : 150, textAlign: "left" }}>
            {item.title}
          </div>
        }
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    // height: "calc(100% - 80px)",
    background: "rgb(7 22 45)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  lastbtn: {
    color: "#969ba1",
    "&:hover": {
      color: "#fff",
    },
  },
  butm: {
    display: "flex",
    justifyContent: "center",
    // "&:hover": {
    //   backgroundColor: "red",
    // },
    paddingBottom: "30px",
  },
  butm1: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  butm2: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          <img
            src="images/logo1.png"
            alt="Logo"
            width="180"
            style={{ marginLeft: "14px" }}
          />
          {/* <Typography
            variant="h3"
            style={{
              color: "#fff",
              paddingLeft: "15px",
              paddingBottom: "8px",
              paddingTop: "12px",
              marginTop: "-28px",
            }}
          >
            Logo
          </Typography> */}
          {sections.map((section, i) => (
            <List
              style={{ paddingBottom: "0px", marginTop: "20px" }}
              key={`menu${i}`}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{
                    paddingLeft: "15px",
                    fontSize: "13px",
                    fontWeight: "300",
                  }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box
            style={{
              paddingLeft: "17px",
              fontSize: "13px",
              fontWeight: "300",
              alignItems: "center",
            }}
          >
            {/* <FaSignOutAlt
              style={{
                paddingLeft: "13px",
                fontSize: "20px",
                fontWeight: "300",
                color: "#fff",
              }}
            /> */}
            <Button
              className={classes.lastbtn}
              startIcon={<FaSignOutAlt />}
              onClick={() => {
                setOpen(true);
              }}
            // style={{ color: "#969ba1" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#52565c", fontSize: "20px" }}
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.butm}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm1}
            to="/"
            component={Link}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm2}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

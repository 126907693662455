import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AuthContext } from "src/context/Auth";
import Apiconfig from "../../../config/APIConfig";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Avatar,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogContent,
  Typography,
} from "@material-ui/core";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import SwipeableTemporaryDrawer from "./RightDrawer";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 50,
    height: 50,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const { account, library, chainId } = useWeb3React();
  const auth = useContext(AuthContext);
  const [userdata, setuserdata] = React.useState("");

  const accessToken = window.localStorage.getItem("creatturAccessToken");

  const profileapi = async () => {
    await axios
      .get(Apiconfig.getAdminProfile, {
        headers: {
          token: `${accessToken}`,
        },
      })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          setuserdata(res.data.result);
          console.log("hjghj)))))0000", res.data.result);
        } else if (res.data.responseCode === 404) {
          console.log("/404");
        } else {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    profileapi();
  }, []);

  return (
    <>
      <Logo
        width="180"
        onClick={() => history.push("/")}
        style={{ cursor: "pointer" }}
      />
      {/* <Typography variant="h3" style={{ color: "#fff", paddingLeft: "15px" }}>
        Logo
      </Typography> */}
      <Box flexGrow={1} />
      {/* <IconButton
        style={{ marginRight: 10 }}
        onClick={() => history.push("/notifications")}
      >
        <Badge badgeContent={auth.NotificationCount} color="secondary">
          <NotificationsIcon style={{ color: "#fff" }} />
        </Badge>
      </IconButton> */}
      {/* <SwipeableTemporaryDrawer /> */}
      {/* <Avatar
        // src={
        // auth.userData.userDetail && auth.userData.userDetail.profilePic !== null
        //   ? auth.userData.userDetail.imageUrl
        //   : "/images/user.png"
        src={
          userdata.profilePic !== null
            ? userdata.profilePic
            : "/images/user.png"
        }
        // }
        className={classes.avatar}
      /> */}
      {/* <Typography style={{ marginLeft: "5px" }}>{userdata.name}</Typography> */}
      <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box ml={3} pb={3}>
              <Typography>
                Please switch to  {NetworkContextName}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
